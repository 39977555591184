import { PageHeading } from '@quad/bootstrap-react';
import { countBy as _countBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form, Table } from 'react-bootstrap';
import AppManagerClient from '../../api/AppManagerClient';
import { IManagingWindowsServiceInstance } from '../../api/models';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import { ApplicationServerManagementToast } from '../../components/ApplicationServerManagement/ApplicationServerManagementToast';
import WindowsServiceStartType from '../../components/ApplicationServerManagement/WindowsServiceStartType';
import WindowsServiceState from '../../components/ApplicationServerManagement/WindowsServiceState';
import { Loading } from '../../components/Shared/Loading';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { AppType } from '../../constants/AppType';
import { ServiceStartType } from '../../constants/ServiceStartType';
import { distinctArray } from '../../helpers/arrayHelpers';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { PopupProps } from '../shared/Popup';

export const UserWindowsServicesDashboard = withHasPermission(() => {
    const [serverApps, setServerApps] = useState(Array<IManagingWindowsServiceInstance>());
    const [isLoading, setIsLoading] = useState(true);
    const [includeDisabledServices, setIncludeDisabledServices] = useState(false);
    const [includeAllServers, setIncludeAllServers] = useState(false);
    const [popupProps, setPopupProps] = useState<PopupProps | undefined>();

    useEffect(() => {
        const loadData = async () => {
            try {
                const windowServices = await AppManagerClient.getUserApplicationsByType(AppType.WindowsService);

                const promises = windowServices.map(async (s) => {
                    return await AppManagerClient.getWindowsServiceForManaging(s.name);
                });

                const instances = (await Promise.all(promises)).flat();

                setServerApps(instances);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        const refreshServerApp = async function (targetApplication: string, serverName: string) {
            const serverData = await AppManagerClient.getWindowsServiceForManagingByServers(targetApplication, [serverName]);

            const updatedServerApps = serverApps.map((s) => {
                return serverData.find((newServer) => s.name === newServer.name && s.serverName === newServer.serverName) ?? s;
            });

            setServerApps(updatedServerApps);
        };

        signalREventsClient.onWindowsServiceEvent.setHook('WindowsServicesDashboardHook', (data) => {
            const index = serverApps.findIndex((s) => s.name === data.targetApplication && s.serverName === data.targetServer);
            if (index === -1) {
                return;
            }

            if (data.isSuccess) {
                refreshServerApp(data.targetApplication, data.targetServer);
            }

            if (data.isFailure) {
                setPopupProps({
                    header: `Operation on [${data.targetServer}] failed.`,
                    body: `[${data.type}]: ${data.errorMessage}`,
                    type: 'danger',
                    autohide: true,
                });
            }
        });
    }, [serverApps]);

    const filteredServerApps = useMemo(() => {
        let filtered = serverApps.filter((x) => includeDisabledServices || x.startType !== ServiceStartType.Disabled);

        if (!includeAllServers) {
            const appCountByServerName = _countBy(filtered, (x) => x.serverName);

            filtered = filtered.filter((x) => appCountByServerName[x.serverName] > 1);
        }

        return filtered;
    }, [serverApps, includeDisabledServices, includeAllServers]);

    return (
        <>
            <ApplicationServerManagementToast popupProps={popupProps} onClose={() => setPopupProps(undefined)} />

            <ScrollPageContent>
                <PageHeading>
                    <div className="col">
                        <h1>
                            <i className="fa fa-cogs"></i>Windows Services Dashboard
                        </h1>
                    </div>
                </PageHeading>
                <Container fluid>
                    <Form.Check inline label="Include Disabled Services?" type={'checkbox'} onChange={(e) => setIncludeDisabledServices(!includeDisabledServices)} />
                    <Form.Check
                        inline
                        label="Include All Servers?"
                        type={'checkbox'}
                        title={'By default servers with only a single service are omitted. (fluentdwinsvc)'}
                        onChange={(e) => setIncludeAllServers(!includeAllServers)}
                    />
                    <ServicesTable serviceInstances={filteredServerApps}></ServicesTable>
                    {isLoading && <Loading />}
                </Container>
            </ScrollPageContent>
        </>
    );
}, Permission.Root_WindowsServices);

const ServicesTable = ({ serviceInstances }: { serviceInstances: IManagingWindowsServiceInstance[] }) => {
    const serversByEnvironment = {
        Production: distinctArray(serviceInstances.filter((x) => x.environment?.toUpperCase() === AppEnvironment.Production.toUpperCase()).map((x) => x.serverName)).sort((a, b) =>
            a.localeCompare(b)
        ),
        Beta: distinctArray(serviceInstances.filter((x) => x.environment?.toUpperCase() === AppEnvironment.Beta.toUpperCase()).map((x) => x.serverName)).sort((a, b) =>
            a.localeCompare(b)
        ),
        Development: distinctArray(serviceInstances.filter((x) => x.environment?.toUpperCase() === AppEnvironment.Development.toUpperCase()).map((x) => x.serverName)).sort(
            (a, b) => a.localeCompare(b)
        ),
    };
    const serverNames = [...serversByEnvironment.Production, ...serversByEnvironment.Beta, ...serversByEnvironment.Development];
    // const serverNames = distinctArray(sortWindowsServiceInstances(serviceInstances).map((x) => x.serverName));
    const serviceNames = distinctArray(serviceInstances.map((x) => x.name)).sort((a, b) => a.localeCompare(b));

    // TODO: Don't rely on server names to determine count per environment.

    const tH2Style: React.CSSProperties = {
        top: '35.5px',
    };

    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th colSpan={1} style={{ textAlign: 'center' }}></th>
                        <th colSpan={serversByEnvironment.Production.length} style={{ textAlign: 'center' }} className={'btn-green'}>
                            Production
                        </th>
                        <th colSpan={serversByEnvironment.Beta.length} style={{ textAlign: 'center' }} className={'btn-orange'}>
                            Beta
                        </th>
                        <th colSpan={serversByEnvironment.Development.length} style={{ textAlign: 'center' }} className={'btn-red'}>
                            Development
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} scope="col" className="col-1" style={tH2Style}>
                            Service
                        </th>
                        {serverNames.map((name) => {
                            return (
                                <th key={name} scope="col" className="col-1" style={tH2Style}>
                                    {name}
                                </th>
                            );
                        })}
                        {/*<th scope="col" className="col-1"></th>*/}
                    </tr>
                </thead>
                <tbody>
                    {serviceNames.map((serviceName) => {
                        return (
                            <tr key={serviceName}>
                                <td>
                                    <a target={'_blank'} rel="noreferrer" href={`applications/windows-service/${serviceName}`}>
                                        {serviceName}
                                    </a>
                                </td>
                                {serverNames.map((serverName) => {
                                    const key = serverName + ':' + serviceName;

                                    const index = serviceInstances.findIndex((x) => x.serverName === serverName && x.name === serviceName);
                                    return index === -1 ? (
                                        <td key={key}>{''}</td> // Service not found on server.
                                    ) : (
                                        <ServerAppStatus key={key} serverApp={serviceInstances[index]} />
                                    );
                                })}
                                {/*<td>
                                <ActionButton
                                    disabled={false}
                                    spinning={false}
                                    icon="fa-refresh" variant="btn-dark-blue" title="Refresh"
                                    onClick={async () => {}}
                                />
                            </td>*/}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

function ServerAppStatus({ serverApp }: { serverApp: IManagingWindowsServiceInstance }) {
    return (
        <td>
            <WindowsServiceState {...serverApp} /> (<WindowsServiceStartType {...serverApp} />)
        </td>
    );
}
