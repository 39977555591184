import { useEffect, useState } from 'react';
import { IManagingAppPoolsInstance } from '../../api/models';
import { AppPoolAction } from '../../constants/AppPoolAction';
import { EventType } from '../../constants/EventType';
import { ServerApplicationClientEventSummaryHandler } from '../../helpers/ServerWindowsServiceClientEventSummaryHandler';
import useRefedProp from '../../hooks/useRefedProp';
import { Permission } from '../../permissions/permission';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerApplicationClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { ServerAppPoolDetails } from '../AppPoolsInfo/AppPoolsInfoView';
import { InfoModal } from '../Shared/InfoModal';
import { ActionButton } from '../UIExtentions/Buttons';
import { AppPoolServerRowProps } from './AppPoolsServerRow';

export interface AppPoolsServerRowActionsProps {
    serverApp: IManagingAppPoolsInstance;
    userPrincipal: UserPrincipal;
    serverUserPrincipal: UserPrincipal;
    onClickCallback: (action: AppPoolAction) => Promise<void>;
    onTimeoutCallback: (data: ServerApplicationClientEventSummary) => void;
}

export function AppPoolsServerRowActions(props: AppPoolServerRowProps) {
    const [showInfo, setShowInfo] = useState(false);
    const [startSpinning, setStartSpinning] = useState(false);
    const [stopSpinning, setStopSpinning] = useState(false);
    const [recycleSpinning, setRecycleSpinning] = useState(false);
    const [refreshSpinning, setRefreshSpinning] = useState(false);
    const onTimeoutCallbackRef = useRefedProp(props.onTimeoutCallback);

    const serverApp = props.serverApp;
    const userPrincipal = props.userPrincipal;
    const serverUserPrincipal = props.serverUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = function (permission: Permission): boolean {
        return userPrincipal.hasPermission(permission) || serverUserPrincipal?.hasPermission(permission);
    };

    useEffect(() => {
        const appPoolStartHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.AppPoolStartTriggered],
            [EventType.AppPoolStartFailed],
            [EventType.AppPoolStartSucceeded],
            () => setStartSpinning(false),
            () => setStartSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const appPoolStopHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.AppPoolStopTriggered],
            [EventType.AppPoolStopFailed],
            [EventType.AppPoolStopSucceeded],
            () => setStopSpinning(false),
            () => setStopSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );
        const appPoolRecycleHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.AppPoolRecycleTriggered],
            [EventType.AppPoolRecycleFailed],
            [EventType.AppPoolRecycleSucceeded],
            () => setRecycleSpinning(false),
            () => setRecycleSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const appPoolRefreshHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.AppPoolRefreshTriggered],
            [EventType.AppPoolRefreshFailed],
            [EventType.AppPoolRefreshSucceeded],
            () => setRefreshSpinning(false),
            () => setRefreshSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        function isForMe(data: ServerApplicationClientEventSummary) {
            return data.targetApplication === serverApp.name && data.targetServer === serverApp.serverName;
        }

        signalREventsClient.onAppPoolEvent.setHook(`AppPoolsServerRow_${serverApp.name}_${serverApp.serverName}`, (data) => {
            if (isForMe(data)) {
                appPoolStartHandler.handleItem(data);
                appPoolStopHandler.handleItem(data);
                appPoolRecycleHandler.handleItem(data);
                appPoolRefreshHandler.handleItem(data);
            }
        });
    }, [onTimeoutCallbackRef, serverApp.name, serverApp.serverName]);

    const infoModalTitle = `${serverApp.name} - ${serverApp.serverName}`;

    return (
        <>
            <td>
                <ActionButton
                    disabled={!getEnableState(Permission.AppPool_Refresh)}
                    spinning={refreshSpinning}
                    icon="fa-refresh"
                    variant="btn-dark-blue"
                    title="Refresh"
                    onClick={() => onClickCallback(AppPoolAction.Refresh)}
                />
            </td>
            <td></td>
            <td className="text-center">
                <ActionButton
                    disabled={!getEnableState(Permission.AppPool_Recycle)}
                    spinning={recycleSpinning}
                    icon="fa-recycle"
                    variant="btn-blue"
                    title="Recycle"
                    onClick={() => onClickCallback(AppPoolAction.Recycle)}
                />
                |
                <ActionButton
                    disabled={!getEnableState(Permission.AppPool_Start)}
                    spinning={startSpinning}
                    icon="fa-play"
                    variant="btn-green"
                    title="Start"
                    onClick={async () => await onClickCallback(AppPoolAction.Start)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.AppPool_Stop)}
                    spinning={stopSpinning}
                    icon="fa-stop"
                    variant="btn-red"
                    title="Stop"
                    onClick={async () => await onClickCallback(AppPoolAction.Stop)}
                />
                |
                <ActionButton icon="fa-info-circle" variant="btn-dark-blue" title="Info" onClick={() => setShowInfo(true)} />
                <InfoModal title={infoModalTitle} show={showInfo} onHide={() => setShowInfo(false)}>
                    <ServerAppPoolDetails serverApp={serverApp}></ServerAppPoolDetails>
                </InfoModal>
            </td>
        </>
    );
}
