import { IManagingApplicationInstance } from '../../api/models';
import { TimeInMilliseconds } from '../../constants/TimeInMilliseconds';
import { LastDiscoveryType } from '../Servers/LastDiscoveryType';

const DefaultExpirationDays = 7;

const GetLastDiscoveryAgoMilis = (lastDiscoveredTimestamp: string | undefined): number | undefined => {
    if (!lastDiscoveredTimestamp) {
        return undefined;
    }
    const lastDiscoveredTimestampDate = new Date(lastDiscoveredTimestamp + '+0000');
    const currentTimestamp = new Date();
    const milisDifference = currentTimestamp.getTime() - lastDiscoveredTimestampDate.getTime();
    return milisDifference;
};

const GetLastDiscoveryType = (lastDiscoveredTimestamp: string | undefined): LastDiscoveryType => {
    const lastDiscoveryAgoMilis = GetLastDiscoveryAgoMilis(lastDiscoveredTimestamp);
    if (!lastDiscoveryAgoMilis) {
        return LastDiscoveryType.Unknown;
    }
    if (lastDiscoveryAgoMilis < TimeInMilliseconds.MINUTE * 15) {
        // < 15 minutes
        return LastDiscoveryType.OK;
    }
    if (lastDiscoveryAgoMilis > TimeInMilliseconds.HOUR && lastDiscoveryAgoMilis < DefaultExpirationDays * TimeInMilliseconds.DAY) {
        // greater than an hour AND less than 7 days
        return LastDiscoveryType.Warning;
    }
    return LastDiscoveryType.Error;
};

interface ApplicationServerDiscoveryStatusProps {
    lastDiscoveryType: LastDiscoveryType;
    discoveryInfo: string;
}
const ApplicationServerDiscoveryStatus = (serverApp: IManagingApplicationInstance): ApplicationServerDiscoveryStatusProps => {
    const lastDiscoveryAgoMilis = GetLastDiscoveryAgoMilis(serverApp.lastDiscoveredTimestamp);
    const lastDiscoveryType = GetLastDiscoveryType(serverApp.lastDiscoveredTimestamp);

    switch (lastDiscoveryType) {
        case LastDiscoveryType.Unknown:
            return {
                lastDiscoveryType: lastDiscoveryType,
                discoveryInfo: 'Unknown',
            };
        case LastDiscoveryType.OK:
            return {
                lastDiscoveryType: lastDiscoveryType,
                discoveryInfo: 'Normal',
            };
        case LastDiscoveryType.Warning:
            return {
                lastDiscoveryType: lastDiscoveryType,
                discoveryInfo: GetExpirationDateCount(lastDiscoveryAgoMilis!),
            };
        case LastDiscoveryType.Error:
            return {
                lastDiscoveryType: lastDiscoveryType,
                discoveryInfo: GetExpirationDateCount(lastDiscoveryAgoMilis!),
            };
    }
};

const GetExpirationDateCount = (lastDiscoveryAgoMilis: number): string => {
    const lastDiscoveryAgoExpirationDifference = lastDiscoveryAgoMilis - DefaultExpirationDays * TimeInMilliseconds.DAY;
    const lastDiscoveryAgoExpirationDifferenceAbs = Math.abs(lastDiscoveryAgoExpirationDifference);

    const days = Math.floor(lastDiscoveryAgoExpirationDifferenceAbs / TimeInMilliseconds.DAY);
    const hours = Math.floor((lastDiscoveryAgoExpirationDifferenceAbs % TimeInMilliseconds.DAY) / TimeInMilliseconds.HOUR);

    const message = lastDiscoveryAgoExpirationDifference > 0 ? 'App has been expired for ' : 'App will expire in ';

    return message + days + ' days and ' + hours + ' hours';
};

export { ApplicationServerDiscoveryStatus, GetLastDiscoveryType };
