export enum Permission {
    Unknown = 'Unknown',
    AtLeastOne = 'AtLeastOne',
    WindowsService_Start = 'WindowsService_Start',
    WindowsService_Stop = 'WindowsService_Stop',
    WindowsService_Restart = 'WindowsService_Restart',
    WindowsService_Kill = 'WindowsService_Kill',
    WindowsService_Uninstall = 'WindowsService_Uninstall',
    WindowsService_Install = 'WindowsService_Install',
    WindowsService_Refresh = 'WindowsService_Refresh',
    WindowsService_ChangeStartupType = 'WindowsService_ChangeStartupType',
    Application_ChangeOwners = 'Application_ChangeOwners',
    Application_ChangeContributors = 'Application_ChangeContributors',
    Application_RefreshGroupMembers = 'Application_RefreshGroupMembers',
    Application_Delete = 'Application_Delete',
    Root_WindowsServices = 'Root_WindowsServices',
    Root_AppPools = 'Root_AppPools',
    Root_Applications = 'Root_Applications',
    Root_InstallAgent = 'Root_InstallAgent',
    Root_Servers = 'Root_Servers',
    Root_WebSites = 'Root_WebSites',
    Root_VirtualApps = 'Root_VirtualApps',
    Root_ScheduledTasks = 'Root_ScheduledTasks',
    AppPool_Recycle = 'AppPool_Recycle',
    AppPool_Refresh = 'AppPool_Refresh',
    AppPool_Start = 'AppPool_Start',
    AppPool_Stop = 'AppPool_Stop',
    WebSite_Refresh = 'WebSite_Refresh',
    VirtualApp_Refresh = 'VirtualApp_Refresh',
    Server_ChangeOwners = 'Server_ChangeOwners',
    Server_ChangeContributors = 'Server_ChangeContributors',
    Server_UpdateCustomProperties = 'Server_UpdateCustomProperties',
    ScheduledTask_Refresh = 'ScheduledTask_Refresh',
    ScheduledTask_Start = 'ScheduledTask_Start',
    ScheduledTask_Stop = 'ScheduledTask_Stop',
    ScheduledTask_Enable = 'ScheduledTask_Enable',
    ScheduledTask_Disable = 'ScheduledTask_Disable',
}
