import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../areas/app/Checkbox';
import { ApplicationServerDiscoveryStatus } from '../ApplicationServerManagement/ApplicationServerDiscoveryStatus';
import { getLastDiscoveryIcon, getLastDiscoveryTextColor, LastDiscoveryType } from '../Servers/LastDiscoveryType';
import { VirtualAppsServerRowActions, VirtualAppsServerRowActionsProps } from './VirtualAppsServerRowActions';

export interface VirtualAppsServerRowProps extends VirtualAppsServerRowActionsProps {
    selected: boolean;
    selectionChangedCallback: Function;
}

export function VirtualAppsServerRow(props: VirtualAppsServerRowProps) {
    const [selected, setSelected]: [any, Function] = React.useState(false);
    const [lastDiscoveredType, setLastDiscoveredType] = React.useState(LastDiscoveryType.Unknown);
    const [iconClassName, setIconClassName] = React.useState('');
    const [discoveryInfo, setDiscoveryInfo] = React.useState('Unknown');

    const serverApp = props.serverApp;

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        const discoveryStatus = ApplicationServerDiscoveryStatus(serverApp);

        setLastDiscoveredType(discoveryStatus.lastDiscoveryType);
        setDiscoveryInfo(discoveryStatus.discoveryInfo);
    }, [serverApp]);

    useEffect(() => {
        setIconClassName('fa ' + getLastDiscoveryIcon(lastDiscoveredType) + ' ' + getLastDiscoveryTextColor(lastDiscoveredType));
    }, [lastDiscoveredType]);

    return (
        <tr>
            <td>
                <Checkbox selected={selected} selectionChangedCallback={(checked: boolean) => props.selectionChangedCallback(checked, serverApp.serverName)} />
            </td>
            <td className="details-link">
                <div className={iconClassName} title={discoveryInfo}></div> &nbsp;
                <Link to={`/servers/${serverApp.serverName}`}>{serverApp.serverName}</Link>
            </td>
            <td>{serverApp.environment}</td>
            <VirtualAppsServerRowActions {...props}></VirtualAppsServerRowActions>
        </tr>
    );
}
