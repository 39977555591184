import { ApplicationPermissionClientEventSummary, ServerApplicationClientEventSummary, ServerPermissionClientEventSummary } from './models';
import { SignalRClient } from './SignalRClient';
import { SignalREventsClientHook } from './SignalREventsClientHook';

export enum SignalREventsClientEvents {
    OnWindowsServiceEvent = 'OnWindowsServiceEvent',
    OnApplicationPermissionEvent = 'OnApplicationPermissionEvent',
    OnAppPoolEvent = 'OnAppPoolEvent',
    OnServerPermissionEvent = 'OnServerPermissionEvent',
    OnWebSiteEvent = 'OnWebSiteEvent',
    OnVirtualAppEvent = 'OnVirtualAppEvent',
    OnScheduledTaskEvent = 'OnScheduledTaskEvent',
}

class SignalREventsClient extends SignalRClient {
    private static instance: SignalREventsClient;
    public onWindowsServiceEvent: SignalREventsClientHook<ServerApplicationClientEventSummary>;
    public onApplicationPermissionEvent: SignalREventsClientHook<ApplicationPermissionClientEventSummary>;
    public onAppPoolEvent: SignalREventsClientHook<ServerApplicationClientEventSummary>;
    public onServerPermissionEvent: SignalREventsClientHook<ServerPermissionClientEventSummary>;
    public onWebSiteEvent: SignalREventsClientHook<ServerApplicationClientEventSummary>;
    public onVirtualAppEvent: SignalREventsClientHook<ServerApplicationClientEventSummary>;
    public onScheduledTaskEvent: SignalREventsClientHook<ServerApplicationClientEventSummary>;

    private constructor() {
        const onWindowsServiceEvent = new SignalREventsClientHook<ServerApplicationClientEventSummary>(SignalREventsClientEvents.OnWindowsServiceEvent);
        const onApplicationPermissionEvent = new SignalREventsClientHook<ApplicationPermissionClientEventSummary>(SignalREventsClientEvents.OnApplicationPermissionEvent);
        const onAppPoolEvent = new SignalREventsClientHook<ServerApplicationClientEventSummary>(SignalREventsClientEvents.OnAppPoolEvent);
        const onServerPermissionEvent = new SignalREventsClientHook<ServerPermissionClientEventSummary>(SignalREventsClientEvents.OnServerPermissionEvent);
        const onWebSiteEvent = new SignalREventsClientHook<ServerApplicationClientEventSummary>(SignalREventsClientEvents.OnWebSiteEvent);
        const onVirtualAppEvent = new SignalREventsClientHook<ServerApplicationClientEventSummary>(SignalREventsClientEvents.OnVirtualAppEvent);
        const onScheduledTaskEvent = new SignalREventsClientHook<ServerApplicationClientEventSummary>(SignalREventsClientEvents.OnScheduledTaskEvent);

        super('signalr/events', [
            onWindowsServiceEvent,
            onApplicationPermissionEvent,
            onAppPoolEvent,
            onServerPermissionEvent,
            onWebSiteEvent,
            onVirtualAppEvent,
            onScheduledTaskEvent,
        ]);

        this.onWindowsServiceEvent = onWindowsServiceEvent;
        this.onApplicationPermissionEvent = onApplicationPermissionEvent;
        this.onAppPoolEvent = onAppPoolEvent;
        this.onServerPermissionEvent = onServerPermissionEvent;
        this.onWebSiteEvent = onWebSiteEvent;
        this.onVirtualAppEvent = onVirtualAppEvent;
        this.onScheduledTaskEvent = onScheduledTaskEvent;
    }

    public static getInstance(): SignalREventsClient {
        if (!SignalREventsClient.instance) {
            SignalREventsClient.instance = new SignalREventsClient();
        }

        return SignalREventsClient.instance;
    }
}

const signalREventsClient = SignalREventsClient.getInstance();
export default signalREventsClient;
