import { useEffect, useState } from 'react';
import { IManagingScheduledTasksInstance } from '../../api/models';
import { EventType } from '../../constants/EventType';
import { ServerApplicationClientEventSummaryHandler } from '../../helpers/ServerWindowsServiceClientEventSummaryHandler';
import useRefedProp from '../../hooks/useRefedProp';
import { Permission } from '../../permissions/permission';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerApplicationClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { InfoModal } from '../Shared/InfoModal';
import { ActionButton } from '../UIExtentions/Buttons';
import { ScheduledTaskserverRowProps } from './ScheduledTasksServerRow';
import { ScheduledTaskAction } from '../../constants/ScheduledTaskAction';
import { ServerScheduledTaskDetails } from '../ScheduledTasksInfo/ScheduledTasksInfoView';

export interface ScheduledTaskServerRowActionsProps {
    serverApp: IManagingScheduledTasksInstance;
    userPrincipal: UserPrincipal;
    serverUserPrincipal: UserPrincipal;
    onClickCallback: (action: ScheduledTaskAction) => Promise<void>;
    onTimeoutCallback: (data: ServerApplicationClientEventSummary) => void;
}

export function ScheduledTasksServerRowActions(props: ScheduledTaskserverRowProps) {
    const [showInfo, setShowInfo] = useState(false);
    const [refreshSpinning, setRefreshSpinning] = useState(false);
    const [startSpinning, setStartSpinning] = useState(false);
    const [stopSpinning, setStopSpinning] = useState(false);
    const [enableSpinning, setEnableSpinning] = useState(false);
    const [disableSpinning, setDisableSpinning] = useState(false);
    const onTimeoutCallbackRef = useRefedProp(props.onTimeoutCallback);

    const serverApp = props.serverApp;
    const userPrincipal = props.userPrincipal;
    const serverUserPrincipal = props.serverUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = function (permission: Permission): boolean {
        return userPrincipal.hasPermission(permission) || serverUserPrincipal?.hasPermission(permission);
    };

    useEffect(() => {
        const scheduledTaskRefreshHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.ScheduledTaskRefreshTriggered],
            [EventType.ScheduledTaskRefreshFailed],
            [EventType.ScheduledTaskRefreshSucceeded],
            () => setRefreshSpinning(false),
            () => setRefreshSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );
        const scheduledTaskStartHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.ScheduledTaskStartTriggered],
            [EventType.ScheduledTaskStartFailed],
            [EventType.ScheduledTaskStartSucceeded],
            () => setStartSpinning(false),
            () => setStartSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );
        const scheduledTaskStopHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.ScheduledTaskStopTriggered],
            [EventType.ScheduledTaskStopFailed],
            [EventType.ScheduledTaskStopSucceeded],
            () => setStopSpinning(false),
            () => setStopSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );
        const scheduledTaskEnableHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.ScheduledTaskEnableTriggered],
            [EventType.ScheduledTaskEnableFailed],
            [EventType.ScheduledTaskEnableSucceeded],
            () => setEnableSpinning(false),
            () => setEnableSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );
        const scheduledTaskDisableHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.ScheduledTaskDisableTriggered],
            [EventType.ScheduledTaskDisableFailed],
            [EventType.ScheduledTaskDisableSucceeded],
            () => setDisableSpinning(false),
            () => setDisableSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        function isForMe(data: ServerApplicationClientEventSummary) {
            return data.targetApplication === serverApp.name && data.targetServer === serverApp.serverName;
        }

        signalREventsClient.onScheduledTaskEvent.setHook(`ScheduledTaskServerRow_${serverApp.name}_${serverApp.serverName}`, (data) => {
            if (isForMe(data)) {
                scheduledTaskRefreshHandler.handleItem(data);
                scheduledTaskStartHandler.handleItem(data);
                scheduledTaskStopHandler.handleItem(data);
                scheduledTaskEnableHandler.handleItem(data);
                scheduledTaskDisableHandler.handleItem(data);
            }
        });
    }, [onTimeoutCallbackRef, serverApp.name, serverApp.serverName]);

    const infoModalTitle = `${serverApp.name} - ${serverApp.serverName}`;

    return (
        <>
            <td>
                <ActionButton
                    disabled={!getEnableState(Permission.ScheduledTask_Refresh)}
                    spinning={refreshSpinning}
                    icon="fa-refresh"
                    variant="btn-dark-blue"
                    title="Refresh"
                    onClick={() => onClickCallback(ScheduledTaskAction.Refresh)}
                />
            </td>
            <td className="text-center">
                <ActionButton
                    disabled={!getEnableState(Permission.ScheduledTask_Start)}
                    spinning={startSpinning}
                    icon="fa-play"
                    variant="btn-green"
                    title="Start"
                    onClick={async () => await onClickCallback(ScheduledTaskAction.Start)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.ScheduledTask_Stop)}
                    spinning={stopSpinning}
                    icon="fa-stop"
                    variant="btn-red"
                    title="Stop"
                    onClick={async () => await onClickCallback(ScheduledTaskAction.Stop)}
                />
                |
                <ActionButton
                    disabled={!getEnableState(Permission.ScheduledTask_Enable)}
                    spinning={enableSpinning}
                    icon="fa-square-check"
                    variant="btn-orange"
                    title="Enable"
                    onClick={async () => await onClickCallback(ScheduledTaskAction.Enable)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.ScheduledTask_Disable)}
                    spinning={disableSpinning}
                    icon="fa-ban"
                    variant="btn-dark-grey"
                    title="Disable"
                    onClick={async () => await onClickCallback(ScheduledTaskAction.Disable)}
                />
                <ActionButton icon="fa-info-circle" variant="btn-dark-blue" title="Info" onClick={() => setShowInfo(true)} />
                <InfoModal title={infoModalTitle} show={showInfo} onHide={() => setShowInfo(false)}>
                    <ServerScheduledTaskDetails serverApp={serverApp} />
                </InfoModal>
            </td>
        </>
    );
}
