import { PageHeading } from '@quad/bootstrap-react';
import { Badge, Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppType } from '../../constants/AppType';
import { Permission } from '../../permissions/permission';
import ScrollPageContent from '../shared/ScrollPageContent';
import { AuthorizationWrapper } from './AuthorizationWrapper';

const Home = () => {
    return (
        <>
            <ScrollPageContent>
                <PageHeading>
                    <div className="col">
                        <h1>
                            <i className="fa fa-home"></i> App Manager Home
                        </h1>
                    </div>
                </PageHeading>
                <Container fluid>
                    <AuthorizationWrapper showAccessDenied={false} permission={Permission.AtLeastOne} isLoading={false}>
                        <Row className="justify-content-center">
                            <Col className="col-6">
                                <Card>
                                    <Card.Header>Apps Management</Card.Header>
                                    <Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to="/applications/apppool">
                                                        <span className="fa-solid fa-globe"></span>
                                                        &nbsp;&nbsp;
                                                        <b>App Pool Management</b>
                                                    </Link>
                                                    &nbsp;&nbsp;
                                                </Card.Title>
                                                <Card.Text>Recycle your app pools.</Card.Text>
                                            </ListGroupItem>

                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to={`/applications/${AppType.ScheduledTask}`}>
                                                        <span className="fa fa-clock"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Scheduled Task Management</b>
                                                    </Link>
                                                    &nbsp;&nbsp;
                                                    <Badge bg="secondary">New</Badge>
                                                </Card.Title>
                                                <Card.Text>Manage your scheduled tasks.</Card.Text>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to={`/applications/${AppType.VirtualApp}`}>
                                                        <span className="fa-solid fa-window-maximize"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Virtual App Management</b>
                                                    </Link>
                                                </Card.Title>
                                                <Card.Text>View your virtual apps.</Card.Text>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to={`/applications/${AppType.WebSite}`}>
                                                        <span className="fa fa-sitemap"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Web Site Management</b>
                                                    </Link>
                                                </Card.Title>
                                                <Card.Text>View your web sites.</Card.Text>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to="/applications/windows-service">
                                                        <span className="fa fa-cogs"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Windows Service Management</b>
                                                    </Link>
                                                </Card.Title>
                                                <Card.Text>Full control over your Windows Services.</Card.Text>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col className="col-6">
                                <Card>
                                    <Card.Header>Server Management</Card.Header>
                                    <Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to="/servers">
                                                        <span className="fa fa-server"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Servers</b>
                                                    </Link>
                                                    &nbsp;&nbsp;
                                                </Card.Title>
                                                <Card.Text>Manage server owners and contributors.</Card.Text>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col className="col-6">
                                <Card>
                                    <Card.Header>Dashboard</Card.Header>
                                    <Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to={'/dashboards/appstatus'}>
                                                        <span className="fa fa-signal"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Application Status Dashboard</b>
                                                    </Link>
                                                    &nbsp;&nbsp;
                                                    <Badge bg="secondary">New</Badge>
                                                </Card.Title>
                                                <Card.Text>Application status table.</Card.Text>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to={`/dashboards/${AppType.WindowsService}`}>
                                                        <span className="fa fa-flask"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Windows Service Dashboard</b>
                                                    </Link>
                                                    &nbsp;&nbsp;
                                                    <Badge bg="secondary">Experimental</Badge>
                                                </Card.Title>
                                                <Card.Text>Dashboard with all your Windows Services.</Card.Text>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col className="col-6">
                                <Card>
                                    <Card.Header>Swagger</Card.Header>
                                    <Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>
                                                <Card.Title>
                                                    <Link to={'/swagger'}>
                                                        <span className="fa fa-code"></span>
                                                        &nbsp;&nbsp;
                                                        <b>Swagger</b>
                                                    </Link>
                                                    &nbsp;&nbsp;
                                                    <Badge bg="secondary">New</Badge>
                                                </Card.Title>
                                                <Card.Text>API Swagger</Card.Text>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </AuthorizationWrapper>
                </Container>
            </ScrollPageContent>
        </>
    );
};

export default Home;
