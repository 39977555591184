import { useCallback, useEffect, useState } from 'react';
import { IManagingWindowsServiceInstance } from '../../api/models';
import { EventType } from '../../constants/EventType';
import { ServiceAction } from '../../constants/ServiceAction';
import { ServiceStartType } from '../../constants/ServiceStartType';
import { ServerApplicationClientEventSummaryHandler } from '../../helpers/ServerWindowsServiceClientEventSummaryHandler';
import useRefedProp from '../../hooks/useRefedProp';
import { Permission } from '../../permissions/permission';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerApplicationClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { InfoModal } from '../Shared/InfoModal';
import { ActionButton } from '../UIExtentions/Buttons';
import { ServerWindowsServiceDetails } from '../WindowsServiceInstallInfo/WindowsServiceInstallInfoView';
import { ApplicationUninstall } from '../ApplicationInstallation/ApplicationUninstall';

export interface ServerRowActionsProps {
    serverApp: IManagingWindowsServiceInstance;
    userPrincipal: UserPrincipal;
    serverUserPrincipal: UserPrincipal;
    onClickCallback: (action: ServiceAction, startType?: ServiceStartType) => Promise<void>;
    onTimeoutCallback: (data: ServerApplicationClientEventSummary) => void;
}

export function ServerRowActions(props: ServerRowActionsProps) {
    const [showInfo, setShowInfo] = useState(false);
    const [startSpinning, setStartSpinning] = useState(false);
    const [stopSpinning, setStopSpinning] = useState(false);
    const [restartSpinning, setRestartSpinning] = useState(false);
    const [killSpinning, setKillSpinning] = useState(false);
    const [changeStartupTypeToManualSpinning, setChangeStartupTypeToManualSpinning] = useState(false);
    const [changeStartupTypeToAutomaticSpinning, setChangeStartupTypeToAutomaticSpinning] = useState(false);
    const [changeStartupTypeToDisabledSpinning, setChangeStartupTypeToDisabledSpinning] = useState(false);
    const [changeStartupTypeToManualDisabled, setChangeStartupTypeToManualDisabled] = useState(false);
    const [changeStartupTypeToAutomaticDisabled, setChangeStartupTypeToAutomaticDisabled] = useState(false);
    const [changeStartupTypeToDisabledDisabled, setChangeStartupTypeToDisabledDisabled] = useState(false);
    const [refreshSpinning, setRefreshSpinning] = useState(false);
    const onTimeoutCallbackRef = useRefedProp(props.onTimeoutCallback);

    const serverApp = props.serverApp;
    const userPrincipal = props.userPrincipal;
    const serverUserPrincipal = props.serverUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = function (permission: Permission): boolean {
        return userPrincipal.hasPermission(permission) || serverUserPrincipal?.hasPermission(permission);
    };

    const setChangeStartupTypesEnabled = async function (enabled: boolean) {
        setChangeStartupTypeToManualDisabled(!enabled);
        setChangeStartupTypeToAutomaticDisabled(!enabled);
        setChangeStartupTypeToDisabledDisabled(!enabled);
    };

    const setChangeStartupTypesSpinning = async function (spinning: boolean) {
        setChangeStartupTypeToManualSpinning(spinning);
        setChangeStartupTypeToAutomaticSpinning(spinning);
        setChangeStartupTypeToDisabledSpinning(spinning);
    };

    const serviceChangeStartupTypeTriggerEventHandler = useCallback((event: ServerApplicationClientEventSummary) => {
        switch (event.typeDetails as ServiceStartType) {
            case ServiceStartType.Manual:
                setChangeStartupTypeToManualSpinning(true);
                break;
            case ServiceStartType.Automatic:
                setChangeStartupTypeToAutomaticSpinning(true);
                break;
            case ServiceStartType.Disabled:
                setChangeStartupTypeToDisabledSpinning(true);
                break;
            default:
                break;
        }
        setChangeStartupTypesEnabled(false);
    }, []);

    const serviceChangeStartupTypeEventHandler = useCallback(() => {
        setChangeStartupTypesSpinning(false);
        setChangeStartupTypesEnabled(true);
    }, []);

    useEffect(() => {
        const serviceStartHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.WindowsServiceStartTriggered],
            [EventType.WindowsServiceStartFailed],
            [EventType.WindowsServiceStartSucceeded],
            () => setStartSpinning(false),
            () => setStartSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const serviceStopHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.WindowsServiceStopTriggered],
            [EventType.WindowsServiceStopFailed],
            [EventType.WindowsServiceStopSucceeded],
            () => setStopSpinning(false),
            () => setStopSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const serviceRestartHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.WindowsServiceRestartTriggered],
            [EventType.WindowsServiceRestartFailed],
            [EventType.WindowsServiceRestartSucceeded],
            () => setRestartSpinning(false),
            () => setRestartSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const serviceKillHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.WindowsServiceKillTriggered],
            [EventType.WindowsServiceKillFailed],
            [EventType.WindowsServiceKillSucceeded],
            () => setKillSpinning(false),
            () => setKillSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const serviceChangeStartupTypeHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.WindowsServiceChangeStartTypeTriggered],
            [EventType.WindowsServiceChangeStartTypeFailed],
            [EventType.WindowsServiceChangeStartTypeSucceeded],
            serviceChangeStartupTypeEventHandler,
            serviceChangeStartupTypeTriggerEventHandler,
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        const serviceRefreshHandler = new ServerApplicationClientEventSummaryHandler(
            serverApp.serverName,
            [EventType.WindowsServiceRefreshTriggered],
            [EventType.WindowsServiceRefreshFailed],
            [EventType.WindowsServiceRefreshSucceeded],
            () => setRefreshSpinning(false),
            () => setRefreshSpinning(true),
            undefined,
            undefined,
            (data) => onTimeoutCallbackRef.current?.(data)
        );

        function isForMe(data: ServerApplicationClientEventSummary) {
            return data.targetApplication === serverApp.name && data.targetServer === serverApp.serverName;
        }

        signalREventsClient.onWindowsServiceEvent.setHook(`ServerRow_${serverApp.name}_${serverApp.serverName}`, (data) => {
            if (isForMe(data)) {
                serviceStartHandler.handleItem(data);
                serviceStopHandler.handleItem(data);
                serviceRestartHandler.handleItem(data);
                serviceKillHandler.handleItem(data);
                serviceChangeStartupTypeHandler.handleItem(data);
                serviceRefreshHandler.handleItem(data);
            }
        });
    }, [onTimeoutCallbackRef, serverApp.name, serverApp.serverName, serviceChangeStartupTypeEventHandler, serviceChangeStartupTypeTriggerEventHandler]);

    const infoModalTitle = `${serverApp.name} - ${serverApp.serverName}`;

    return (
        <>
            <td>
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_Refresh)}
                    spinning={refreshSpinning}
                    icon="fa-refresh"
                    variant="btn-dark-blue"
                    title="Refresh"
                    onClick={async () => await onClickCallback(ServiceAction.Refresh)}
                />
            </td>
            <td>
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_Start)}
                    spinning={startSpinning}
                    icon="fa-play"
                    variant="btn-green"
                    title="Start"
                    onClick={async () => await onClickCallback(ServiceAction.Start)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_Stop)}
                    spinning={stopSpinning}
                    icon="fa-stop"
                    variant="btn-red"
                    title="Stop"
                    onClick={async () => await onClickCallback(ServiceAction.Stop)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_Restart)}
                    spinning={restartSpinning}
                    icon="fa-step-forward"
                    variant="btn-blue"
                    title="Restart"
                    onClick={async () => await onClickCallback(ServiceAction.Restart)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_Kill)}
                    spinning={killSpinning}
                    icon="fa-bomb"
                    variant="btn-dark-grey"
                    title="Kill"
                    onClick={async () => await onClickCallback(ServiceAction.Kill)}
                />
                |
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_ChangeStartupType) || changeStartupTypeToManualDisabled}
                    spinning={changeStartupTypeToManualSpinning}
                    icon="fa-hand-o-up"
                    variant="btn-grey-blue"
                    title="Set Manual"
                    onClick={async () => await onClickCallback(ServiceAction.ChangeStartupType, ServiceStartType.Manual)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_ChangeStartupType) || changeStartupTypeToAutomaticDisabled}
                    spinning={changeStartupTypeToAutomaticSpinning}
                    icon="fa-bolt"
                    variant="btn-orange"
                    title="Set Auto"
                    onClick={async () => await onClickCallback(ServiceAction.ChangeStartupType, ServiceStartType.Automatic)}
                />
                <ActionButton
                    disabled={!getEnableState(Permission.WindowsService_ChangeStartupType) || changeStartupTypeToDisabledDisabled}
                    spinning={changeStartupTypeToDisabledSpinning}
                    icon="fa-ban"
                    variant="btn-dark-grey"
                    title="Set Disabled"
                    onClick={async () => await onClickCallback(ServiceAction.ChangeStartupType, ServiceStartType.Disabled)}
                />
                |
                <ActionButton icon="fa-info-circle" variant="btn-dark-blue" title="Info" onClick={() => setShowInfo(true)} />
                <InfoModal title={infoModalTitle} show={showInfo} onHide={() => setShowInfo(false)}>
                    <ServerWindowsServiceDetails serverApp={serverApp}></ServerWindowsServiceDetails>
                </InfoModal>
                |
                <ApplicationUninstall applicationName={serverApp.name} server={serverApp.serverName} onClickCallback={props.onClickCallback} userPrincipal={userPrincipal} />
            </td>
        </>
    );
}
