import { PropsWithChildren } from 'react';
import { Accordion } from 'react-bootstrap';
import { Error404 } from '../Shared/Error404NotFound';
import { Loading } from '../Shared/Loading';
import { UnhandledError } from '../Shared/UnhandledError';

export type AccordionWrapperProps = PropsWithChildren<{
    isDefaultOpen?: boolean;
    fitToSize?: boolean;
    headerIconType?: string;
    headerIcon: string;
    headerText: string;
    isLoading?: boolean;
    isDataNotFound?: boolean;
    objectName?: string;
    errorMessage?: string;
}>;

export const AccordionWrapper = (props: AccordionWrapperProps) => {
    const isDefaultOpen = props.isDefaultOpen ?? (false as boolean);
    const fitToSize = props.fitToSize ?? (false as boolean);
    const headerIconType = props.headerIconType ?? 'fa';
    const headerIcon = props.headerIcon;
    const headerText = props.headerText;
    const isLoading = props.isLoading;
    const isDataNotFound = props.isDataNotFound;
    const objectName = props.objectName;
    const errorMessage = props.errorMessage;

    const cssClass = fitToSize ? '' : 'max-height-250';
    return (
        <Accordion defaultActiveKey={isDefaultOpen ? '0' : '-1'}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    {isLoading ? (
                        <>
                            <i className="fa fa-spinner fa-pulse fa-fw"></i>&nbsp;Loading...&nbsp;{headerText}
                        </>
                    ) : headerIcon != null ? (
                        <>
                            <i className={`${headerIconType} ${headerIcon}`}></i>&nbsp;{headerText}
                        </>
                    ) : (
                        <>{headerText}</>
                    )}
                </Accordion.Header>
                <Accordion.Body>
                    {isLoading ? (
                        <Loading text={headerText} />
                    ) : isDataNotFound ? (
                        <>
                            <Error404 objectName={(headerText + ' ' + objectName) as string} />
                        </>
                    ) : errorMessage && errorMessage.length > 0 ? (
                        <UnhandledError message={errorMessage} />
                    ) : props.children ? (
                        <>
                            <div className={cssClass}>{props.children}</div>
                        </>
                    ) : (
                        <></>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
