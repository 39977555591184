import { PageHeading } from '@quad/bootstrap-react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { ApplicationEventLog } from '../../components/EventLog/ApplicationEventLog';
import { ApplicationPermissions } from '../../components/Permissions/ApplicationPermissions';
import { ApplicationDetails } from '../../components/ApplicationDetails/ApplicationDetails';
import { WindowsServiceInstallInfo } from '../../components/WindowsServiceInstallInfo/WindowsServiceInstallInfo';
import { ApplicationServerManagement } from '../../components/ApplicationServerManagement/ApplicationServerManagement';
import { AccordionWrapper } from '../../components/UIExtentions/Accordion';
import AppManagerClient from '../../api/AppManagerClient';
import { UserPrincipal } from '../../permissions/userPrincipal';
import React, { useEffect } from 'react';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { ApplicationPermissionClientEventSummary } from '../../signalr/models';
import ScrollPageContent from '../shared/ScrollPageContent';
import { useParams } from 'react-router-dom';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import { AppType } from '../../constants/AppType';

export const WindowsServiceDetails = withHasPermission(() => {
    const applicationName = useParams<'name'>().name!;

    const [userPrincipal, setUserPrincipal] = React.useState(new UserPrincipal([]));

    const getUserPrincipal = async (appName: string) => {
        const permissionsResult = await AppManagerClient.getWindowsServiceUserPermissions(appName);
        const myUserPrincipal = new UserPrincipal(permissionsResult);
        setUserPrincipal(myUserPrincipal);
    };

    useEffect(() => {
        getUserPrincipal(applicationName);
    }, [applicationName]);

    useEffect(() => {
        function isForMe(data: ApplicationPermissionClientEventSummary) {
            return data.targetApplication === applicationName;
        }

        signalREventsClient.onApplicationPermissionEvent.setHook(`WindowsServiceDetails_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getUserPrincipal(data.targetApplication);
            }
        });
    }, [applicationName]);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa fa-cogs"></i> {applicationName} Details
                    </h1>
                </div>
            </PageHeading>

            <Stack gap={3}>
                <Container>
                    <AccordionWrapper isDefaultOpen={false} headerIcon="fa-info-circle" headerText="Application Details">
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <ApplicationDetails applicationName={applicationName} applicationType={AppType.WindowsService} />
                                </Col>
                                <Col sm={12} md={6}>
                                    <WindowsServiceInstallInfo applicationName={applicationName} />
                                </Col>
                            </Row>
                        </Container>
                    </AccordionWrapper>
                </Container>

                <Container>
                    <ApplicationPermissions applicationName={applicationName} userPrincipal={userPrincipal} applicationType={AppType.WindowsService} />
                </Container>

                <Container>
                    <ApplicationServerManagement applicationName={applicationName} userPrincipal={userPrincipal} />
                </Container>

                <Container>
                    <ApplicationEventLog applicationName={applicationName} applicationType={AppType.WindowsService} />
                </Container>
            </Stack>
        </ScrollPageContent>
    );
}, Permission.Root_WindowsServices);
