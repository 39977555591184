import AppManagerClient from '../../api/AppManagerClient';
import { ApiNonSuccessError, IManagingApplicationInstance, IManagingScheduledTasksInstance, IPostApplicationActionResponse } from '../../api/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PopupProps } from '../../areas/shared/Popup';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import { Loading } from '../Shared/Loading';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { ApplicationServerManagementToast } from '../ApplicationServerManagement/ApplicationServerManagementToast';
import { ServerApplicationClientEventSummary, ServerPermissionClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import _ from 'lodash';
import { ScopedUserPrincipal } from '../../permissions/ScopedUserPrincipal';
import { AppType } from '../../constants/AppType';
import { displayArray } from '../../helpers/arrayHelpers';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ScheduledTaskAction } from '../../constants/ScheduledTaskAction';
import { ScheduledTasksServerTable } from './ScheduledTasksServerTable';
import { ScheduledTasksServerManagementButtons } from './ScheduledTasksServerManagementButtons';
import { nameof } from 'ts-simple-nameof';
import { filter } from '../Shared/Filter';
import { Col, Container, Row } from 'react-bootstrap';
import { Search } from '../Shared/Search';

const getEnvSortValue = (environment: AppEnvironment): number => {
    //ToDo tpiech - this is repeated in few places, could be handled better
    switch (environment) {
        case AppEnvironment.Production:
            return 3;
        case AppEnvironment.Beta:
            return 2;
        default:
            return 1;
    }
};

export const sortScheduledTasksInstances = (list: Array<IManagingScheduledTasksInstance>): Array<IManagingScheduledTasksInstance> => {
    //ToDo tpiech - this is repeated in few places, could be handled better
    list.sort((a, b) => {
        const aEnv = getEnvSortValue(a.environment);
        const bEnv = getEnvSortValue(b.environment);
        if (aEnv === bEnv) {
            // order by server name within the same environment
            return a.serverName.localeCompare(b.serverName);
        }
        return bEnv - aEnv;
    });

    return list;
};

export interface ScheduledTaskActionHandlerProps {
    scheduledTaskName: string;
    servers: string[];
    action: ScheduledTaskAction;
}

export interface ScheduledTasksServerManagementViewProps {
    userPrincipal: UserPrincipal;
    applicationName: string;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
    serverApps: IManagingScheduledTasksInstance[];
}

export const ScheduledTasksServerManagementView = (props: ScheduledTasksServerManagementViewProps) => {
    const [servers, setServers] = useState(new Array<string>());
    const [allSelected, setAllSelected] = useState(false);
    const [serverApps, setServerApps] = useState(props.serverApps);
    const [popupProps, setPopupProps] = useState<PopupProps | undefined>();
    const [serversUserPrincipal, setServersUserPrincipal] = useState(new ScopedUserPrincipal({}));
    const [searchTerm, setSearchTerm] = useState('');

    const applicationName = props.applicationName;

    const filteredServerApps = useMemo(() => {
        const filterFields = [
            nameof<IManagingScheduledTasksInstance>((f) => f.serverName),
            nameof<IManagingScheduledTasksInstance>((f) => f.environment),
            nameof<IManagingScheduledTasksInstance>((f) => f.taskState),
            nameof<IManagingScheduledTasksInstance>((f) => f.status),
        ];
        return filter(serverApps, searchTerm, filterFields);
    }, [searchTerm, serverApps]);

    const getServersUserPrincipal = async (serverApps: IManagingScheduledTasksInstance[]) => {
        const servers = _.map(serverApps, (serverApp) => serverApp.serverName);
        const permissionsResult = await AppManagerClient.getApplicationUserPermissionsForServers(AppType.ScheduledTask, servers);
        const myServersUserPrincipal = new ScopedUserPrincipal(permissionsResult);
        setServersUserPrincipal(myServersUserPrincipal);
    };

    const serverSelectionChanged = useCallback(
        function (selected: boolean, serverName: string) {
            let updatedServers = [...servers];

            if (selected === true) {
                updatedServers.push(serverName);
            } else {
                updatedServers = updatedServers.filter((x) => x !== serverName);
            }

            setServers(updatedServers);
        },
        [servers]
    );

    useEffect(() => {
        getServersUserPrincipal(serverApps);
    }, [serverApps]);

    useEffect(() => {
        function isForMe(data: ServerPermissionClientEventSummary) {
            const servers = _.map(serverApps, (serverApp) => serverApp.serverName);
            return servers.includes(data.targetServer);
        }

        signalREventsClient.onServerPermissionEvent.setHook(`ScheduledTaskServerManagementView_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getServersUserPrincipal(serverApps);
            }
        });
    }, [applicationName, serverApps]);

    useEffect(() => {
        const local = [...props.serverApps];
        sortScheduledTasksInstances(local);
        setServerApps(local);
    }, [props.serverApps]);

    useEffect(() => {
        function isForMe(data: ServerApplicationClientEventSummary) {
            return data.targetApplication === applicationName;
        }

        const refreshServerApp = async function (targetApplication: string, serverName: string) {
            const serverNames = [serverName];
            const serverData = await AppManagerClient.getScheduledTasksForManagingByServers(targetApplication, serverNames);

            //update server list
            const updatedServerApps = serverApps.map((s) => {
                return serverData.find((updatedServer) => s.name === updatedServer.name && s.serverName === updatedServer.serverName) ?? s;
            });

            //add new servers
            serverData.forEach((newServer) => {
                if (updatedServerApps.find((s) => s.name === newServer.name && s.serverName === newServer.serverName) === undefined) {
                    updatedServerApps.push(newServer);
                }
            });

            //remove not found servers
            serverNames.forEach((serverName) => {
                if (serverData.find((missingServer) => targetApplication === missingServer.name && serverName === missingServer.serverName) === undefined) {
                    const removed = _.remove(updatedServerApps, (s) => s.name === targetApplication && s.serverName === serverName);
                    if (removed.length !== 0) {
                        serverSelectionChanged(false, serverName);
                    }
                }
            });

            sortScheduledTasksInstances(updatedServerApps);
            setServerApps(updatedServerApps);
        };

        signalREventsClient.onScheduledTaskEvent.setHook(`ScheduledTasksServerManagementView_${applicationName}`, (data) => {
            if (isForMe(data)) {
                if (data.isSuccess) {
                    refreshServerApp(data.targetApplication, data.targetServer);
                }
                if (data.isFailure) {
                    showPopup({
                        header: `Operation on [${data.targetServer}] failed.`,
                        body: `[${data.type}]: ${data.errorMessage}`,
                        type: 'danger',
                        autohide: true,
                    });
                }
            }
        });
    }, [applicationName, serverApps, serverSelectionChanged]);

    if (serverApps) {
        const allServersSelected = serverApps.every((s: IManagingApplicationInstance) => servers.includes(s.serverName));
        if (allServersSelected !== allSelected) {
            setAllSelected(allServersSelected);
        }
    }

    const allServersSelectionChanged = function (selected: boolean) {
        const allServers = serverApps.map((s: IManagingApplicationInstance) => {
            return s.serverName;
        });
        if (selected) {
            setServers(allServers);
        } else {
            setServers([]);
        }
    };

    const ScheduledTaskActionHandler = async function (props: ScheduledTaskActionHandlerProps) {
        await tryWithErrorHandler(props.action, () => AppManagerClient.postScheduledTasksAction(props.scheduledTaskName, props.action, props.servers));
    };

    const tryWithErrorHandler = async function (action: string, funct: () => Promise<IPostApplicationActionResponse>) {
        try {
            const postResult = await funct();

            showPopup({
                header: `Successfully send action [${action}] to agent.`,
                toCopy: postResult.correlationId,
                body: `Messages: ${displayArray(postResult.messages)}. Warnings: ${displayArray(postResult.warnings)}`,
            });
        } catch (error) {
            const apiError = error as ApiNonSuccessError;
            showPopup({
                header: `Error (${apiError?.status}) while calling [${action}].`,
                type: 'danger',
                body: `Error: ${apiError?.message} `,
            });
        }
    };

    const onTimeout = function (data: ServerApplicationClientEventSummary) {
        showPopup({
            header: `Operation timed out on [${data.targetServer}].`,
            body: `Operation: [${data.type}]`,
            type: 'danger',
            autohide: true,
        });
    };

    const showPopup = function (props: PopupProps) {
        setPopupProps(props);
    };

    return (
        <>
            <ApplicationServerManagementToast popupProps={popupProps} onClose={() => setPopupProps(undefined)} />

            {props.isLoading ? (
                <Loading text="Server Management" />
            ) : (
                <ScheduledTasksServerManagementButtons
                    selectedServers={servers}
                    userPrincipal={props.userPrincipal}
                    serversUserPrincipal={serversUserPrincipal}
                    onClickCallback={(action: ScheduledTaskAction) =>
                        ScheduledTaskActionHandler({
                            scheduledTaskName: applicationName,
                            servers: servers,
                            action: action,
                        })
                    }
                />
            )}

            <AccordionWrapper
                isDefaultOpen={true}
                headerIcon="fa-server"
                headerText="Servers"
                objectName={applicationName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
            >
                <Container>
                    <Row>
                        <Col>
                            <Search onUpdate={setSearchTerm} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ScheduledTasksServerTable
                                userPrincipal={props.userPrincipal}
                                serversUserPrincipal={serversUserPrincipal}
                                allServersSelectionChanged={allServersSelectionChanged}
                                serverSelectionChanged={serverSelectionChanged}
                                onClickCallback={ScheduledTaskActionHandler}
                                serverApps={filteredServerApps}
                                servers={servers}
                                allSelected={allSelected}
                                onTimeoutCallback={onTimeout}
                            />
                        </Col>
                    </Row>
                </Container>
            </AccordionWrapper>
        </>
    );
};
