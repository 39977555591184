import { ServerApplicationClientEventSummary } from '../signalr/models';
import { TimeoutList } from './TimeoutList';

export class ServerApplicationClientEventSummaryHandler {
    private events: TimeoutList<string, ServerApplicationClientEventSummary>;

    public constructor(
        private server: string,
        private addTypes: string[],
        private successTypes: string[],
        private failedTypes: string[],
        private emptyCallback?: () => void,
        private addCallback?: (data: ServerApplicationClientEventSummary) => void,
        private successCallback?: (data: ServerApplicationClientEventSummary) => void,
        private failedCallback?: (data: ServerApplicationClientEventSummary) => void,
        private timeoutCallback?: (data: ServerApplicationClientEventSummary) => void
    ) {
        this.events = new TimeoutList<string, ServerApplicationClientEventSummary>(60000, undefined, this.emptyCallback);
    }

    private isForMe(data: ServerApplicationClientEventSummary): boolean {
        return data.targetServer === this.server;
    }

    public handleItem(data: ServerApplicationClientEventSummary) {
        if (!this.isForMe(data)) {
            return;
        }

        if (this.addTypes.includes(data.type)) {
            this.events.add(data.correlationId, data, (value, timedOut) => {
                if (timedOut) {
                    this.timeoutCallback?.(value);
                }
            });

            this.addCallback?.(data);
        }

        if (this.failedTypes.includes(data.type)) {
            this.failedCallback?.(data);
            this.events.remove(data.correlationId);
        }

        if (this.successTypes.includes(data.type)) {
            this.successCallback?.(data);
            this.events.remove(data.correlationId);
        }
    }
}
