import AppManagerClient from '../../api/AppManagerClient';
import { IEventLog } from '../../api/models';
import { EventLogView } from '../EventLog/EventLogView';
import React, { useEffect } from 'react';
import { ServerApplicationClientEventSummary, ServerPermissionClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';

interface ServerEventLogParams {
    serverName: string;
}
export const ServerEventLog = (props: ServerEventLogParams) => {
    const [eventList, setEventList]: [IEventLog[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const serverName = props.serverName;

    useEffect(() => {
        function isForMe(data: ServerApplicationClientEventSummary | ServerPermissionClientEventSummary) {
            return data.targetServer === serverName;
        }

        signalREventsClient.onWindowsServiceEvent.setHook(`ServerEventLog_${serverName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetServer);
            }
        });

        signalREventsClient.onAppPoolEvent.setHook(`ServerEventLog_${serverName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetServer);
            }
        });

        signalREventsClient.onServerPermissionEvent.setHook(`ServerEventLog_${serverName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetServer);
            }
        });
        signalREventsClient.onScheduledTaskEvent.setHook(`ServerEventLog_${serverName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetServer);
            }
        });
    }, [serverName]);

    const getEventLogs = async (serverName: string) => {
        const query = `$filter=targetServer eq '${serverName}'&$select=type,createdTimestamp,operation,targetServer,summary&$orderby=CreatedTimestamp desc&$expand=User($select=DisplayName)`;
        try {
            const eventResult = await AppManagerClient.getEvents(query);
            setEventList(eventResult);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getEventLogs(serverName);
    }, [serverName]);

    return <EventLogView eventList={eventList} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={false} objectName={serverName} />;
};
