import { PageHeading } from '@quad/bootstrap-react';
import React, { useEffect } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AppManagerClient from '../../api/AppManagerClient';
import { ServerEventLog } from '../../components/EventLog/ServerEventLog';
import { ServerPermissions } from '../../components/Permissions/ServerPermissions';
import { ServerAgents } from '../../components/ServerAgents/ServerAgents';
import { ServerApplicationListing } from '../../components/ServerApplicationListing/ServerApplicationListing';
import { ServerInfo } from '../../components/ServerDetails/ServerInfo';
import { AccordionWrapper } from '../../components/UIExtentions/Accordion';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerPermissionClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import ScrollPageContent from '../shared/ScrollPageContent';

export const ServerDetails = withHasPermission(() => {
    const serverName = useParams<'name'>().name!;
    const [userPrincipal, setUserPrincipal] = React.useState(new UserPrincipal([]));

    const getUserPrincipal = async (serverName: string) => {
        const permissionsResult = await AppManagerClient.getServerUserPermissions(serverName);
        const myUserPrincipal = new UserPrincipal(permissionsResult);
        setUserPrincipal(myUserPrincipal);
    };

    useEffect(() => {
        getUserPrincipal(serverName);
    }, [serverName]);

    useEffect(() => {
        function isForMe(data: ServerPermissionClientEventSummary) {
            return data.targetServer === serverName;
        }

        signalREventsClient.onServerPermissionEvent.setHook(`ServerDetails_${serverName}`, (data) => {
            if (isForMe(data)) {
                getUserPrincipal(data.targetServer);
            }
        });
    }, [serverName]);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa fa-server"></i> {serverName} Details
                    </h1>
                </div>
            </PageHeading>

            <Stack gap={3}>
                <Container>
                    <AccordionWrapper isDefaultOpen={false} headerIcon="fa-info-circle" headerText="Server Details">
                        <Container>
                            <ServerInfo serverName={serverName} userPrincipal={userPrincipal} />
                        </Container>
                    </AccordionWrapper>
                </Container>

                <Container>
                    <ServerPermissions serverName={serverName} userPrincipal={userPrincipal} />
                </Container>

                <Container>
                    <ServerAgents serverName={serverName} />
                </Container>

                <Container>
                    <ServerApplicationListing serverName={serverName} />
                </Container>

                <Container>
                    <ServerEventLog serverName={serverName} />
                </Container>
            </Stack>
        </ScrollPageContent>
    );
}, Permission.Root_Servers);
