import _, { Dictionary } from 'lodash';
import { Permission } from './permission';
import { UserPrincipal } from './userPrincipal';

export class ScopedUserPrincipal {
    hasAnyPermissions(): boolean {
        return _.some(this.scopedUserPrincipals, (userPrincipal) => userPrincipal.hasAnyPermissions());
    }
    private scopedUserPrincipals: Dictionary<UserPrincipal> = {};

    constructor(scopedPermissions: Dictionary<Array<string>>) {
        _.forEach(scopedPermissions, (permissions, scope) => {
            this.scopedUserPrincipals[scope] = new UserPrincipal(permissions);
        });
    }

    public getUserPrincipal(scope: string): UserPrincipal {
        return this.scopedUserPrincipals[scope];
    }

    public hasPermission(scopes: Array<string>, permission: Permission): boolean {
        const scopedUserPrincipals = _.pickBy(this.scopedUserPrincipals, function (value, key) {
            return scopes.includes(key);
        });
        return _.every(scopedUserPrincipals, (userPrincipal) => userPrincipal.hasPermission(permission));
    }

    public everyHasPermission(permission: Permission): boolean {
        return _.every(this.scopedUserPrincipals, (userPrincipal) => userPrincipal.hasPermission(permission));
    }
}
