import React from 'react';
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { versionSort } from '../../helpers/sorting';
import { parseVersion } from '../../helpers/Version';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { Agent, AgentsTableRow } from './AgentsTableRow';

interface AgentsTableProps {
    data: Agent[];
}
const AgentsTable = (props: AgentsTableProps) => {
    const [agents, setAgents] = React.useState(new Array<Agent>());

    const sortAgents = async (data: Agent[]) => {
        const AgentVersionSort = (left: Agent, right: Agent) => {
            const leftVersion = parseVersion(left.version);
            const rightVersion = parseVersion(right.version);

            return versionSort(leftVersion, rightVersion);
        };

        setAgents(data.sort(AgentVersionSort));
    };

    useEffect(() => {
        sortAgents(props.data);
    }, [props.data]);

    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-1">
                            <i className="fa fa-star"></i>
                        </th>
                        <th scope="col" className="col-1">
                            Version
                        </th>
                        <th scope="col" className="col-3">
                            Name
                        </th>
                        <th scope="col" className="col-3">
                            Installed Location
                        </th>
                        <th scope="col" className="col-1">
                            RunAs
                        </th>
                        <th scope="col" className="col-3">
                            Created Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {agents.length > 0 ? (
                        agents.map(function (agent: Agent, i: number) {
                            return <AgentsTableRow key={'agent-' + agent.id} {...agent} isPrimary={i === 0} />;
                        })
                    ) : (
                        <EmptyDataRow colSpan={6} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default AgentsTable;
