import { ColumnDefinition, ComparisonTable, RowDefinition, lowestDateDisplayValueFunction } from './ComparisonTable';
import { IManagingApplicationInstance } from '../../api/models';
import { LastDiscoveryType } from '../Servers/LastDiscoveryType';
import { GetLastDiscoveryType } from '../ApplicationServerManagement/ApplicationServerDiscoveryStatus';
import { DetailsColor } from './DetailsColor';
import { Collection } from 'lodash';
import { StatusIconProps } from '../Shared/StatusIcon';
export interface ApplicationInstanceEnvironmentComparisonProps<T extends IManagingApplicationInstance> {
    rows: Array<RowDefinition<T>>;
    data: T[];
}

export function ApplicationInstanceEnvironmentComparison<T extends IManagingApplicationInstance>({ rows, data }: ApplicationInstanceEnvironmentComparisonProps<T>) {
    const columns = [
        { columnName: 'Development', columnValue: 'Development', compareColumnValue: 'Beta', className: 'btn-orange' },
        { columnName: 'Beta', columnValue: 'Beta', compareColumnValue: 'Production', className: 'btn-green' },
        { columnName: 'Production', columnValue: 'Production', compareColumnValue: 'Production', className: 'btn-purple' },
        { columnName: 'Not Specified', columnValue: 'null', compareColumnValue: 'null', className: 'btn-dark-grey' },
    ] as ColumnDefinition[];

    const getDiscoveryRow = (rowName: string, property: keyof T): RowDefinition<T> => {
        return {
            rowName: rowName,
            property: property,
            customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => lowestDateDisplayValueFunction(values),
            customStatusFunction: (value: string): StatusIconProps['statusType'] => {
                const lastDiscoveryType = GetLastDiscoveryType(value);
                switch (lastDiscoveryType) {
                    case LastDiscoveryType.OK:
                        return 'success';
                    case LastDiscoveryType.Warning:
                        return 'infoWarning';
                    default:
                        return 'error';
                }
            },
            customDisplayDetailsValueFunction: (value: string): string => {
                return value ? new Date(value).toLocaleString() : '';
            },
            customDetailsStatusColorFunction: (value: string): DetailsColor => {
                const lastDiscoveryType = GetLastDiscoveryType(value);
                switch (lastDiscoveryType) {
                    case LastDiscoveryType.OK:
                        return 'lightgreen';
                    case LastDiscoveryType.Warning:
                        return 'yellow';
                    default:
                        return 'salmon';
                }
            },
        };
    };
    const allRows = [...rows];

    allRows.unshift({ rowName: 'Version', property: 'version' });
    allRows.push(getDiscoveryRow('Last Discovered', 'lastDiscoveredTimestamp'));
    allRows.push({
        rowName: 'Version Discovered',
        property: 'versionDiscoveredTimestamp',
        customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => lowestDateDisplayValueFunction(values),
        customStatusFunction: (value: string): StatusIconProps['statusType'] => 'success',
        customDisplayDetailsValueFunction: (value: string): string => (value ? new Date(value).toLocaleString() : ''),
        customDetailsStatusColorFunction: (value: string): DetailsColor => 'lightgreen',
    });

    return <ComparisonTable<T> columns={columns} detailsBy="serverName" filterProperty={'environment'} rowName="Property" rows={allRows} data={data} />;
}
