import AppManagerClient from '../../api/AppManagerClient';
import { useEffect, useState } from 'react';
import { ServerInfoView } from './ServerInfoView';
import { IServer } from '../../api/models';
import { UserPrincipal } from '../../permissions/userPrincipal';

interface ServerInfoProps {
    serverName: string;
    userPrincipal: UserPrincipal;
}
export const ServerInfo = (props: ServerInfoProps) => {
    const [isLoading, setLoadingStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const serverName = props.serverName;
    const userPrincipal = props.userPrincipal;

    const [server, setServer] = useState({} as IServer);

    const getServer = async (serverName: string) => {
        try {
            const serverResult = await AppManagerClient.getServerByName(serverName);
            if (serverResult) {
                setServer(serverResult);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getServer(serverName);
    }, [serverName]);

    return <ServerInfoView userPrincipal={userPrincipal} serverName={serverName} server={server} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={!server} />;
};
