import { CloseButton, Modal } from 'react-bootstrap';

export function InfoModal(props: any) {
    return (<>
        <Modal size="lg" centered show={props.show}>
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                <CloseButton onClick={props.onHide} />
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    </>);
}
