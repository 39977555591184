import { AccordionWrapper } from '../UIExtentions/Accordion';
import AgentsTable from '../Agents/AgentsTable';
import { Agent } from '../Agents/AgentsTableRow';

export interface ServerAgentsViewProps {
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage?: string;
    serverName: string;
    agents: Agent[];
}
export const ServerAgentsView = (props: ServerAgentsViewProps) => {
    return (
        <>
            <AccordionWrapper
                isDefaultOpen={false}
                headerIcon="fa-download"
                headerText="Agents"
                objectName={props.serverName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
                isDataNotFound={props.isDataNotFound}
            >
                <AgentsTable data={props.agents} />
            </AccordionWrapper>
        </>
    );
};
