export interface EmptyDataRowProps {
    colSpan?: number;
}

export const EmptyDataRow = (props: EmptyDataRowProps) => {
    return (
        <tr>
            <td colSpan={props.colSpan}>No data found</td>
        </tr>
    );
};
