import { TimeInMilliseconds } from '../../constants/TimeInMilliseconds';
import { getLastDiscoveryIcon, getLastDiscoveryTextColor, LastDiscoveryType } from '../Servers/LastDiscoveryType';
import { AppManagerWikiLink } from './AppManagerWikiLink';

interface ServerLastDiscoveredTimestampProps {
    lastDiscoveredTimestamp?: Date;
}
export function ServerLastDiscoveredTimestamp(props: ServerLastDiscoveredTimestampProps) {
    const lastDiscoveredTimestamp = props.lastDiscoveredTimestamp;

    let lastDiscoveredType = LastDiscoveryType.Error;
    let lastDiscoveredTitle = 'Unknown';
    if (lastDiscoveredTimestamp) {
        const currentTimestamp = new Date();
        const milisDifference = currentTimestamp.getTime() - lastDiscoveredTimestamp.getTime();
        if (milisDifference < TimeInMilliseconds.MINUTE * 15) {
            lastDiscoveredType = LastDiscoveryType.OK;
            lastDiscoveredTitle = 'Normal';
        } else if (milisDifference < TimeInMilliseconds.HOUR) {
            lastDiscoveredType = LastDiscoveryType.Warning;
            lastDiscoveredTitle = 'Discovery is late';
        } else {
            lastDiscoveredTitle = 'Agent seems to be down';
        }
    }

    return (
        <p className={getLastDiscoveryTextColor(lastDiscoveredType)}>
            <i className={'fa ' + getLastDiscoveryIcon(lastDiscoveredType)} title={lastDiscoveredTitle}></i> {lastDiscoveredTimestamp?.toLocaleString()}{' '}
            <AppManagerWikiLink pagePath="Manage-Servers" anchor="last-discovery" />
        </p>
    );
}
