import { Badge } from 'react-bootstrap';

export interface Agent {
    id: number;
    name: string;
    version: string;
    installedLocation: string;
    runAsAccount: string;
    createdTimestamp: string;
    isPrimary?: boolean;
}
export function AgentsTableRow(props: Agent) {
    const createdTimestamp = new Date(props.createdTimestamp + '+0000');
    return (
        <tr>
            {props.isPrimary ? (
                <td>
                    <Badge bg="secondary">Primary</Badge>
                </td>
            ) : (
                <td></td>
            )}
            <td>{props.version}</td>
            <td>{props.name}</td>
            <td>
                <div className="agent-installed-location" title={props.installedLocation}>
                    {props.installedLocation}
                </div>
            </td>
            <td>{props.runAsAccount}</td>
            <td>{createdTimestamp.toLocaleString()}</td>
        </tr>
    );
}
