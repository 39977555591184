export enum LastDiscoveryType {
    Unknown,
    Warning,
    Error,
    OK,
}

export function getLastDiscoveryIcon(type: LastDiscoveryType) {
    switch (type) {
        case LastDiscoveryType.OK:
            return 'fa fa-check-circle';
        case LastDiscoveryType.Warning:
            return 'fa-exclamation-triangle';
        case LastDiscoveryType.Error:
        case LastDiscoveryType.Unknown:
        default:
            return 'fa-exclamation-circle';
    }
}

export function getLastDiscoveryTextColor(type: LastDiscoveryType) {
    switch (type) {
        case LastDiscoveryType.OK:
            return 'text-success';
        case LastDiscoveryType.Warning:
            return 'text-warning';
        case LastDiscoveryType.Error:
        case LastDiscoveryType.Unknown:
        default:
            return 'text-danger';
    }
}
